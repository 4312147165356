<template>
  <mds-modal
    :title="title"
    :visible="showModal"
    @mds-modal-dismissed="closeModal($event)"
    class="user-engagement-modal"
  >
    <div class="modal-content">
      <h2 class="sub-title">{{ subTitle }}</h2>
      <div class="button-container">
        <mds-button
          @click="handleMeeting"
          class="top-button"
          variation="secondary"
          size="touch"
          data-ee-test="user-engagement-modal-top-btn"
          type="button"
        >
          {{ topButtonText }}
        </mds-button>
        <mds-button
          v-if="contactNumber"
          @click="handlePhoneCall"
          class="talk-now-btn"
          variation="primary"
          size="touch"
          data-ee-test="user-engagement-modal-bottom-btn"
          type="button"
        >
          {{ bottomButtonText }} {{ contactNumber }}
        </mds-button>
      </div>
    </div>
  </mds-modal>
</template>
<script>
import MdsModal from '@mds/modal';
import { MdsButton } from '@mds/button';
export default {
  name: 'UserEngagementModal',
  components: {
    MdsModal,
    MdsButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    subTitle: {
      type: String,
      required: false,
      default: null
    },
    topButtonText: {
      type: String,
      required: false,
      default: null
    },
    bottomButtonText: {
      type: String,
      required: false,
      default: null
    },
    contactNumber: {
      type: String,
      required: false,
      default: null
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal($event) {
      this.$emit('user-engagement-modal-dismissed', $event);
    },
    handleMeeting() {
      window.open(
        'https://calendly.com/morningstar-retirement-education/consultation',
        '_blank'
      );
    },
    handlePhoneCall() {
      window.location.href = `tel:${this.contactNumber}`;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/user-engagement-modal.scss';
@import '@/assets/css/client/ingg/user-engagement-modal.scss';
</style>
