<template>
  <EeContentWrapper>
    <AdviceIntro />
    <ManagedAccounts />
    <FloatingButton ref="triggerButton" :handleClick="openModal" />
    <UserEngagementModal
      :showModal="showModal"
      @user-engagement-modal-dismissed="closeModal"
      :contact-number="productSupportNumber"
      :title="getIdleModalContent('title')"
      :sub-title="getIdleModalContent('subTitle')"
      :top-button-text="getIdleModalContent('topBtnText')"
      :bottom-button-text="getIdleModalContent('bottomBtnText')"
    />
  </EeContentWrapper>
</template>

<script>
import store from '@/simpleStore';
import EeContentWrapper from './layout/ee-content-wrapper/';
import AdviceIntro from './AdviceIntro';
import ManagedAccounts from './ManagedAccounts';
import FloatingButton from './Common/FloatingButton';
import UserEngagementModal from '@/components/modals/UserEngagementModal';
import { mapActions } from 'vuex';
import { createIdleActivityMonitor } from '@/shared/utils/idleActivityMonitor';
import { CONTACT_INFO, TIMERS } from '../constants';
import apiService from './service/api-service';

export default {
  components: {
    EeContentWrapper,
    AdviceIntro,
    ManagedAccounts,
    FloatingButton,
    UserEngagementModal
  },
  data() {
    return {
      showModal: false,
      productSupportNumber: null,
      idleTime: TIMERS.IDLE_TIME_LANDING
    };
  },
  created() {
    localStorage.setItem('showDefaultLandingLinks', `${!store.isAMA()}`);
    this.setDefaultHeroTitle();
  },
  mounted() {
    this.fetchContactInfo();

    const idleTimer = createIdleActivityMonitor(this.idleTime, this.openModal);
    idleTimer.startTimer();
  },
  beforeDestroy() {
    if (this.idleTimer) {
      this.idleTimer.stopTimer();
    }
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText'
    }),
    setDefaultHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');

      this.setHeroText(heroText);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;

      // Ensure the button loses focus
      const buttonElement = this.$refs.triggerButton.$el.querySelector(
        'button'
      );
      if (buttonElement) {
        buttonElement.blur();
      }
    },
    async fetchContactInfo() {
      try {
        const { cl } = this.$route.query;
        const clientId = cl ? cl.toUpperCase() : null;
        const baseUri = clientId ? `${CONTACT_INFO}/${clientId}` : CONTACT_INFO;

        const responseData = await apiService.getAPIRequest(baseUri);

        this.productSupportNumber =
          responseData?.data?.ProductSupportNumber ?? null;
      } catch (error) {
        console.error('Error fetching contact info:', error);
        this.productSupportNumber = null;
      } finally {
        sessionStorage.setItem(
          'ProductSupportNumber',
          this.productSupportNumber
        );
      }
    },
    getIdleModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.landing.idleModalContent.${key}`
      );
    }
  }
};
</script>
