<template>
  <div class="advice">
    <EeContentWrapper has-special-offer>
      <retirement-income
        @confirm-enrollment="confirmEnrollment"
        :isButtonDisabled="isButtonDisabled"
      />
      <card-wrapper>
        <special-offer />
        <enhancements />
      </card-wrapper>
      <strategy-details class="strategy" />
      <p class="cta">
        <MdsButton
          @click="confirmEnrollment('Bottom')"
          :disabled="isButtonDisabled"
          variation="primary"
          size="touch"
          data-ee-test="advice-enrollment-button"
        >
          {{ $contentTransposer('button.enrollNow') }}
        </MdsButton>
      </p>
      <FloatingButton ref="triggerButton" :handleClick="openIdleModal" />
      <UserEngagementModal
        :showModal="showIdleModal"
        @user-engagement-modal-dismissed="closeIdleModal"
        :contact-number="productSupportNumber"
        :title="getIdleModalContent('title')"
        :sub-title="getIdleModalContent('subTitle')"
        :top-button-text="getIdleModalContent('topBtnText')"
        :bottom-button-text="getIdleModalContent('bottomBtnText')"
      />
    </EeContentWrapper>
    <ConfirmEnrollmentModal
      v-if="showConfirmEnrollmentModal"
      @closed-modal="closeModal($event)"
      @confirm="enrollParticipant"
    />
  </div>
</template>

<script>
import store from '@/simpleStore';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import ConfirmEnrollmentModal from '@/components/modals/ConfirmEnrollmentModal';
import CardWrapper from './CardWrapper';
import RetirementIncome from './RetirementIncome';
import SpecialOffer from './SpecialOffer';
import Enhancements from './Enhancements';
import EeContentWrapper from '../layout/ee-content-wrapper/';
import StrategyDetails from './StrategyDetails';
import FloatingButton from '../Common/FloatingButton';
import UserEngagementModal from '@/components/modals/UserEngagementModal';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import enrollPpt from './utils/enrollPpt';
import {
  epTracking,
  epEnrollment
} from '../../shared/utils/engagementPlatform';
import { PAGE_ID, TIMERS } from '../../constants';
import { createIdleActivityMonitor } from '@/shared/utils/idleActivityMonitor';

export default {
  components: {
    EeContentWrapper,
    MdsButton,
    CardWrapper,
    RetirementIncome,
    SpecialOffer,
    Enhancements,
    StrategyDetails,
    ConfirmEnrollmentModal,
    FloatingButton,
    UserEngagementModal
  },
  data() {
    return {
      showConfirmEnrollmentModal: false,
      showIdleModal: false,
      productSupportNumber: null,
      idleTime: TIMERS.IDLE_TIME_ADVICE
    };
  },
  computed: {
    ...mapGetters({
      getIsEnrollButtonDisable: 'getIsEnrollButtonDisable'
    }),
    isButtonDisabled() {
      return this.getIsEnrollButtonDisable;
    }
  },
  created() {
    const proposalAdvice = store.getProposalAdvice();
    this.setProposalAdvice(proposalAdvice);
    this.setIncomeProjection(proposalAdvice);
    localStorage.setItem('showDefaultLandingLinks', false);
    this.setHeroTitle();
  },
  mounted() {
    newRelicTracking();
    if (
      window &&
      (window.location.origin.includes('-qa.') ||
        window.location.origin.includes('//localhost:'))
    ) {
      epTracking(
        store.getParticipantId(),
        PAGE_ID.ADVICE,
        sessionStorage.getItem('sessionUserId')
      );
    }

    const contactNumber = sessionStorage.getItem('ProductSupportNumber');
    this.productSupportNumber = contactNumber || null;

    const idleTimer = createIdleActivityMonitor(
      this.idleTime,
      this.openIdleModal
    );
    idleTimer.startTimer();
  },
  beforeDestroy() {
    if (this.idleTimer) {
      this.idleTimer.stopTimer();
    }
  },
  methods: {
    ...mapActions({
      setHeroText: 'setHeroText',
      setShowLoader: 'setShowLoader',
      setProposalAdvice: 'setProposalAdvice',
      setIncomeProjection: 'setIncomeProjection'
    }),
    setHeroTitle() {
      const heroText = this.$contentTransposer('advice.heroTitle');

      this.setHeroText(heroText);
    },
    closeModal($event) {
      this.showConfirmEnrollmentModal = $event;
    },
    confirmEnrollment(btnId) {
      newRelicTracking([{ key: 'enrollNowButton', value: btnId }]);
      this.showConfirmEnrollmentModal = true;
    },
    async enrollParticipant() {
      this.setShowLoader(true);
      this.closeModal(false);

      const apiResponse = await enrollPpt();
      if (apiResponse.status === 'error') {
        this.$router.push({
          path: '/errorPage',
          query: store.getQueryParams()
        });
        this.setShowLoader(false);
        return;
      }

      if (
        window &&
        (window.location.origin.includes('-qa.') ||
          window.location.origin.includes('//localhost:'))
      ) {
        epEnrollment(
          store.getParticipantId(),
          sessionStorage.getItem('sessionUserId')
        );
      }

      this.setShowLoader(false);

      this.$router.push({
        path: '/enrollment',
        query: store.getQueryParams()
      });
    },
    openIdleModal() {
      this.showIdleModal = true;
    },
    closeIdleModal() {
      this.showIdleModal = false;

      // Ensure the button loses focus
      const buttonElement = this.$refs.triggerButton.$el.querySelector(
        'button'
      );
      if (buttonElement) {
        buttonElement.blur();
      }
    },
    getIdleModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.advice.idleModalContent.${key}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/advice.scss';
</style>
