import {
  ADVISORY_AGREEMENT,
  PRIVACY_POLICY,
  METHODOLOGY,
  CONTACT_US
} from '../../../constants';

export default {
  button: {
    getAdvice: 'Get Advice',
    tryAgain: 'Try Again',
    endSession: 'End Session',
    extendMySession: 'Extend My Session',
    enrollNow: 'Enroll Now',
    confirmEnrollment: 'Confirm Enrollment'
  },
  landing: {
    introductionTxt: `<p>Welcome! As part of your employee benefits package, your employer has made Morningstar&reg; Retirement Manager<sup class="ee__sup">SM</sup> available to you to help you get on track for your retirement goals. 
      Morningstar&reg; Retirement Manager<sup class="ee__sup">SM</sup> is designed to provide you with a personalized retirement strategy and the option for professional, ongoing management of your retirement account.</p>
      <p>Before we get started, let’s confirm your information below with what we have on file from your employer so we can provide you with a sneak peek of your personalized retirement strategy.</p>`,
    label: {
      lastName: 'Last Name',
      entryCode: 'Entry Code',
      dateOfBirth: 'Date of Birth'
    },
    verifyForm: {
      header: 'Confirm your information.',
      subHeader: `By confirming your information, we can provide you with a sneak peek of the personalized recommendations you’ll receive in Morningstar&reg; Retirement Manager<sup class="ee__sup">SM</sup>.`,
      requiredField: 'Required field',
      dobPlaceholder: 'MM/DD/YYYY',
      enrollMicrocopy:
        'Check your email or mailer we sent you for this number.',
      privacyPolicy: {
        text: 'Read our',
        link: 'Privacy Policy'
      }
    },
    statusText: {
      inCorrectFieldValues: {
        header: "We're having trouble verifying your identity",
        text: `<p>There could be multiple reasons for this to occur:
        The details you entered do not match our records, or your entry code may have expired.</p>
        <p class="not-eligible__text--padding">Please ensure your information is correct. If you’re still having trouble, contact your Plan Provider.</p>`,
        textWithPhone: `<p>There could be multiple reasons for this to occur:
        The details you entered do not match our records, or your entry code may have expired.</p>
        <p class="not-eligible__text--padding">Please ensure your information is correct. If you’re still having trouble, contact our support team at {{supportNumber}}.</p>`
      },
      blockSGSTParticipantText: `Unfortunately, we are unable to process your enrollment at this time. Please contact
        your service provider, Sentry Insurance, to enroll into the service. <span class="not-eligible__email">401kparticipantservices@sentry.com</span> / 800-473-6879`
    },
    footerLinks: {
      // This footer links is used to show on landing page for non AMA users because it doesn't have client specific content.
      privacyPolicy: {
        name: 'Privacy Policy',
        type: 'modal',
        modal: PRIVACY_POLICY,
        title: 'Morningstar Investment Management LLC Privacy Policy',
        show: true
      },
      firmBrochure: {
        name: 'Firm Brochure and Brochure Supplement',
        type: 'link',
        url: 'https://s3.amazonaws.com/work-rm-compliance-docs/FORM_ADV.pdf',
        show: true
      },
      relationshipSummary: {
        name: 'Relationship Summary',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_RS.pdf',
        show: true
      },
      conversationStarters: {
        name: 'Conversation Starters',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_Conversation_Starters.pdf',
        show: true
      }
    }
  },
  aboutMorningStar: {
    header: 'We’re about putting investors first—all investors.',
    columns: [
      {
        imageID: 'mission',
        imagePath: 'our-mission',
        className: 'purple',
        subHeader: 'Our mission',
        description:
          'When it comes to helping people reach their financial goals, we’re all in. We’re focused on providing practical, unbiased advice so you can make better decisions about your money.'
      },
      {
        imageID: 'history',
        imagePath: 'our-history',
        className: 'violet',
        subHeader: 'Our history',
        description:
          'We’re a team of investment analysts and researchers with unshakeable standards, and Morningstar, Inc., our parent company, has been helping investors reach for their financial goals since 1984.'
      },
      {
        imageID: 'commitment',
        imagePath: 'our-commitment',
        className: 'red',
        subHeader: 'Our commitment',
        description:
          'We don’t get paid extra to recommend certain securities to you. We work for you––your bottom line is the only one that matters.'
      }
    ]
  },
  footer: {
    disclaimers: `<p class="ee__body-text--s">©{{year}} Morningstar Investment LLC. All rights reserved. Morningstar
      Investment LLC is a registered investment adviser and wholly owned
      subsidiary of Morningstar, Inc. The Morningstar name and logo are
      registered marks of Morningstar, Inc.</p>
      <p class="ee__body-text--s">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"
      target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">
      Terms of Service</a> apply.</p>
      {{disclaimerSpecialOffer}}
      <p class="ee__body-text--s">The information contained in this document is the proprietary material
      of Morningstar Investment Management. Reproduction, transcription, or
      other use, by any means, in whole or in part, without the prior
      written consent of Morningstar Investment Management, is prohibited.
      Opinions expressed are as of the current date; such opinions are
      subject to change without notice. Morningstar Investment Management
      shall not be responsible for any trading decisions, damages, or other
      losses resulting from, or related to, the information, data, analyses
      or opinions or their use. This commentary is for informational
      purposes only. This information, data, analyses, and opinions
      presented herein do not constitute investment advice, are provided
      solely for information purposes and therefore are not an offer to buy
      or sell a security.</p>
      <p class="ee__body-text--s">This commentary contains certain forward-looking statements. We use
      words such as "expects", "anticipates", "believes", "estimates",
      "forecasts", and similar expressions to identify forward-looking
      statements. Such forward-looking statements involve known and unknown
      risks, uncertainties and other factors which may cause the actual
      results to differ materially and/or substantially from any future
      results, performance or achievements expressed or implied by those
      projected in the forward-looking statements for any reason.</p>`,
    disclaimersOfferedBy: `<p class="ee__body-text--s">Morningstar® Retirement Manager<sup class="ee__sup">SM</sup> is
      offered by Morningstar Investment Management LLC, a registered
      investment adviser and subsidiary of Morningstar, Inc., and is
      intended for citizens or legal residents of the United States or its
      territories. The Morningstar name and logo are registered marks of
      Morningstar, Inc. Investment advice generated by Morningstar
      Retirement Manager is based on information provided and limited to the
      investment options available in the defined contribution plan.
      Projections and other information regarding the likelihood of various
      retirement income and/or investment outcomes are hypothetical in
      nature, do not reflect actual results, and are not guarantees of
      future results. Results may vary with each use and overtime.</p>
      <p class="ee__body-text--s">Morningstar Investment Management does not guarantee that the results
      of their advice, recommendations, or the objectives of an investment
      option will be achieved. All investments involve risk, including the
      loss of principal. There can be no assurance that any financial
      strategy will be successful. Morningstar Investment Management does
      not guarantee that the results of their advice, recommendations or
      objectives of a strategy will be achieved.</p>`,
    contact: {
      content: '{{phoneNumber}}',
      providerTitle: '',
      providerDescription: '',
      riaTitle: '',
      riaDescription: ''
    },
    footerLinks: {
      /* This footer links is used to show client specific content
      /* for AMA users from landing page onwards when isAMA flag is true in case valid url parameters exists.
      /* And also for nonAMA users from advice page onwards after verify api call.*/
      methodology: {
        name: 'Methodology',
        type: 'modal',
        modal: METHODOLOGY,
        title: 'Methodology',
        show: true
      },
      advisoryAgreement: {
        name: 'Advisory Agreement',
        type: 'modal',
        modal: ADVISORY_AGREEMENT,
        title: 'Investment Advisory Agreement',
        show: true
      },
      privacyPolicy: {
        name: 'Privacy Policy',
        type: 'modal',
        modal: PRIVACY_POLICY,
        title: 'Morningstar Investment Management LLC Privacy Policy',
        show: true
      },
      disclosureStatement: {
        show: false
      },
      advisoryServicesAgreement: {
        show: false
      },
      firmBrochure: {
        id: 'firmBrochureUrl',
        name: 'Firm Brochure and Brochure Supplement',
        type: 'link',
        url: 'https://s3.amazonaws.com/work-rm-compliance-docs/FORM_ADV.pdf',
        show: true
      },
      relationshipSummary: {
        id: 'relationshipSummaryUrl',
        name: 'Relationship Summary',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_RS.pdf',
        show: true
      },
      conversationStarters: {
        id: 'conversationStartersUrl',
        name: 'Conversation Starters',
        type: 'link',
        url:
          'https://work-rm-compliance-docs.s3.amazonaws.com/Form_ADV_Part_3_Conversation_Starters.pdf',
        show: true
      },
      contactUs: {
        name: 'Contact Us',
        type: 'modal',
        modal: CONTACT_US,
        title: 'Contact Us',
        show: true
      }
    }
  },
  modals: {
    confirmEnrollment: {
      acknowledge: `I acknowledge that I have received Morningstar Investment Management's
        <a href="{{firmBrochureLink}}" target="_blank">{{firmBrochureName}}</a>,
        <a href="{{relationshipSummaryLink}}" target="_blank">{{relationshipSummaryName}}</a> and
        <a href="{{advisoryAgreementLink}}" target="_blank">{{advisoryAgreementName}}</a>, and I consent
        to those terms and conditions. Upon your enrollment, we will instruct your provider to make
        any changes to your savings rate or investments if needed.`,
      bannerText: 'Confirm Enrollment',
      buttonBack: 'Back',
      buttonConfirm: 'Confirm Enrollment',
      description: `Confirm your enrollment in Morningstar Retirement Manager
        today and let us guide you to the strategy we created for you.`,
      title: "You're almost there!"
    },
    incomeProjection: {
      bannerText: 'Adjust expected retirement age',
      buttonBack: 'Back',
      description: `Delaying your retirement by a few years can significantly improve your income outlook. 
      Use the slider below to adjust your retirement age and see your projected income changes.`,
      salaryRangeDescription: `Your salary and retirement age can greatly impact your income in retirement. 
      Delaying your retirement by a few years can significantly improve your income outlook. 
      Select your estimated salary and use the slider below to adjust your retirement age and see your projected income changes.`,
      disclaimer: `*The changes made here are exploratory only. To make official changes or explore how
        other factors impact your account, such as adding additional accounts, changing savings rates,
        and adjusting retirement age, please go to Morningstar Retirement Manager post enrollment.`,
      info: 'Projected retirement income:',
      label: 'Age {{age}}'
    },
    userEngagement: {
      landing: {
        idleModalContent: {
          title: 'Would you rather speak to a Retirement Education Consultant?',
          subTitle: 'Let’s discuss your retirement options on a call!',
          topBtnText: 'Schedule a zoom call at your earliest convenience',
          bottomBtnText: 'Want to talk now? Call us at'
        }
      },
      advice: {
        idleModalContent: {
          title: 'Need more information?',
          subTitle:
            'Talk to a Morningstar Retirement Education Consultant today.',
          topBtnText: 'Schedule a zoom call at your earliest convenience',
          bottomBtnText: 'Want to talk now? Call us at'
        }
      }
    }
  },
  advice: {
    heroTitle: 'You’re verified! Now, let’s talk about your retirement.',
    disclaimer: `*This information is based on the age, account balance, current fund allocation, current
      contributions, and plan type provided by your plan provider. It may also consider brokerage
      accounts, retirement loans, gender and the state where you live, if we receive those inputs from
      your plan provider. If you have savings earmarked for retirement outside of your retirement plan
      account, such as a pension, social security benefits, spousal assets, or other retirement
      accounts, we encourage you to log into Morningstar Retirement Manager by going to your plan
      provider’s website so that you can provide us with details and we can further personalize our
      suggestions for you. We assume a current retirement age of 65. We believe holding your employer’s
      stock can increase your portfolio risk. If applicable, you direct us to sell off your non-restricted
      company stock according to our methodology when you click "Confirm Enrollment" on this page.`,
    enhancementTitle:
      'We can make the following enhancements to your strategy:',
    planInfo: {
      labelAndBalance: 'Your {{planInfolabel}} with ${{planInfobalance}} saved'
    },
    savingsRate: {
      proposed:
        'We recommend you <strong>save {{proposedSavingsRate}}% of each paycheck</strong>.',
      current:
        'You are currently saving {{currentSavingsRate}}% of your paycheck.'
    },
    retirementAge: {
      text:
        'Explore how your expected retirement age can impact your retirement income.',
      button: 'Adjust your retirement age'
    },
    riskLevel: {
      proposed: `Take a <strong>{{proposedRiskLabel}}</strong> level of risk with your investments. More stocks
        can mean more potential for growth but also more risk. Based on your situation, we think this is the right
        balance for you.*`,
      current:
        'Currently, your investments are at a <strong>{{currentRiskLabel}}</strong> risk level.'
    },
    fee: {
      feeText: `This service requires a {{percentage}}% fee, calculated based on your account balance.
        Included in this fee is expert advice that is tailored to your personal financial situation,
        as well as continuous management of your account to help it work harder for you on an ongoing basis.`
    },
    strategy: {
      header:
        'Enroll in Morningstar Retirement Manager today, and let us guide you towards that level of increase.',
      subHeader: 'When you enroll:',
      contents: [
        'We’ll implement these recommendations into your retirement account.',
        `The professionals at Morningstar Investment Management LLC will continue to monitor your retirement
          accounts and update them as needed, so your account can work harder for you on an ongoing basis to
          help you meet your retirement goals.`
      ]
    },
    confirmEnrollmentHeader: 'Confirm Enrollment',
    retirementIncome: {
      proposedRetirementAgeAndIncomeText: `If you enroll in Morningstar Retirement Manager and let our retirement professionals help
        tweak your strategy, we project you may have <strong>&dollar;{{proposedRetirementIncome}} to spend each 
        month</strong> during your retirement!`
    },
    projectionDisclosure: {
      shortText: `IMPORTANT: The projections or other information generated by this analysis regarding the likelihood of 
      various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees 
      of future results.`,
      fullText: `<p class="ee__body-text--s" id="projection-disclosure">IMPORTANT: The projections or other information generated 
      by this analysis regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual 
      investment results and are not guarantees of future results.  Results may vary with use and over time, reflecting any changed 
      circumstances, assumptions or variables upon which the analysis is based. The analysis relates only to the current and 
      proposed asset allocation strategies shown herein, other strategies not considered may have characteristics similar or 
      superior to those being analyzed. </p>
      <p class="ee__body-text--s">
      Projections involve known and unknown risks, uncertainties, and other factors which may cause actual results to differ 
      materially and substantially from any future results or performance expressed or implied by the projection for any reason. 
      Projections in no way represent a guarantee that a particular result will be produced or achieved. This analysis uses asset 
      classes as a proxy for investments. Security implementation decisions may result in significantly different outcomes.
      </p>
      <p class="ee__body-text--s">
      Additional important information about the methodology, assumptions, risks, and limitations associated with the income 
      projections are presented <a target="_blank" href="https://work-rm-compliance-docs.s3.amazonaws.com/Retirement+Projection+Disclosures.pdf">here</a>.
      </p>
      `
    }
  },
  enroll: {
    header: {
      done: "You're almost done, ",
      set: "You're all set, "
    },
    confirmationText: `Our team is thrilled to help you get and stay on track for your retirement goals. Morningstar
      Investment Management is now managing the investments in your <strong>{{accountText}}</strong> and will keep
      an eye on things for you.`,
    emailText:
      ' Look out for a confirmation email to <strong>{{email}}</strong>.',
    accountDetails: {
      header: "Wait&mdash;there's one more step!",
      text: `We need your help to finish putting your plan into action. Sign in through the <strong
      data-ee-test="provider-display-name">{{providerDisplayName}}</strong> website to make these changes in your account(s).`
    }
  },
  advisorFirmInfo: {
    productName: '',
    advisorFirmName: ''
  },
  sessionExpired: {
    signOutMsg: `We signed you out automatically after 15 minutes to keep your personal
    information secure. To sign back in, verify your identity and click "Get
    Advice".`
  },
  sessionTimeOut: {
    warningMsg: [
      `It looks like you've been inactive for a while. To keep your information secure, we will
      automatically end your session soon.`,
      `If you need more time to gather information or make a decision, extend your session.`
    ]
  },
  hero: {
    title: "There's an easier way to save and invest for retirement."
  },
  alreadyEnrolled: {
    warningMsg: `You previously enrolled in the service on {{enrollmentDate}}`,
    header: 'Looks like you are already enrolled!',
    messageText: [
      `Our records indicate you already confirmed your enrollment. We've sent your enrollment request to your plan provider for Processing.`,
      `Please visit your plan provider's retiment portal to access your account.`
    ]
  },
  errorPage: {
    alertTitle: `Error`,
    alertText: `Your enrollment was unsuccessful.`,
    header: `Oh no! Looks like something went wrong with your enrollment.`,
    messageText: `An unexpected error has occurred. Please contact customer support to finalize your enrollment.`,
    contactButton: `Contact Customer Support`
  }
};
